<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="mt-6 ml-6 mr-6">
    <div class="title">
      <h1>
        {{ $t('nodeUpdate.title') }}
      </h1>
      <v-divider />
    </div>
    <div>
      <v-row>
        <v-col cols="12" lg="5">
          <the-nerve-table
            id="iiotNodeUpdateListTable"
            :columns="nodeUpdateColumns"
            :is-row-clickable="canAccess('UI_NODE_UPDATE:UPDATE')"
            :is-action-menu-enabled="false"
            store-module="node-update-list"
            custom-fetch-action="node_update_list"
            custom-list-getter="getNodeUpdateList"
            :have-access="canAccess('UI_SUBNAV_NODE_UPDATE:VIEW')"
            :is-search-enabled="false"
            :is-filter-columns-enabled="false"
            :empty-state="$t('nodeUpdate.nodeUpdateList.emptyText')"
            @row-clicked="(params) => showCompatibleNodes(params.item)"
          />
        </v-col>
        <v-col cols="12" lg="7">
          <div v-if="isCompatibleNodes" id="iiotNodeUpdateListCompatibleNodes">
            <v-toolbar dark>
              <v-toolbar-title>{{ $t('nodeUpdate.compatibleNodesTitle') }}</v-toolbar-title>
            </v-toolbar>
            <v-card class="compatibleNodesCard">
              <the-nerve-table
                :id="'iiotCompatibleNodesList'"
                :params="params"
                :columns="compatibleNodesColumns"
                :is-action-menu-enabled="false"
                store-module="node-update-list"
                custom-fetch-action="compatible_nodes"
                custom-list-getter="getCompatibleDevices"
                custom-count-getter="getTotalCompatibleDevices"
                :is-search-enabled="true"
                :is-filter-columns-enabled="false"
                :empty-state="$t('nodeUpdate.compatibleNodesList.emptyText')"
                :is-check-box-column-without-select-all-enabled="true"
                class="ml-5 pr-5"
                :is-row-clickable="false"
                check-item-action="select_device"
                selected-items-getter="getSelectedNodeDevices"
                :class="{
                  compatibleNodesTable: getCompatibleNodes.length !== 0 && canAccess('UI_NODE_UPDATE:UPDATE'),
                  compatibleNodesTableEmpty:
                    getCompatibleNodes.length === 0 ||
                    (getCompatibleNodes.length !== 0 && !canAccess('UI_NODE_UPDATE:UPDATE')),
                }"
                @params-changed="updateParams"
              >
                <template #additional-actions>
                  <v-col class="mt-n1">
                    <v-checkbox
                      id="iiotNodeUpdateListSelectAllCheckbox"
                      v-model="selectAll"
                      :disabled="getCompatibleNodes.length === 0 || !canAccess('UI_NODE_UPDATE:UPDATE')"
                      :label="$t('nodeUpdate.selectAll')"
                      @click="selectRemoveNodes"
                    />
                  </v-col>
                </template>
              </the-nerve-table>
              <div class="pb-5">
                <nerve-button
                  id="iiotNodeUpdateListUpdateNodesButton"
                  :text="$t('nodeUpdate.updateButton')"
                  :disabled="
                    getCompatibleNodes.length === 0 ||
                    !canAccess('UI_NODE_UPDATE:UPDATE') ||
                    getSelectedNodeDevices.length === 0
                  "
                  type-of-btn="action"
                  size="normal"
                  class="ml-5"
                  @click-event="updateNodeToNewVersion"
                />
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TheNerveTable, NerveButton } from 'nerve-ui-components';
import Logger from '@/utils/logger';

export default {
  components: { TheNerveTable, NerveButton },
  data() {
    return {
      isCompatibleNodes: false,
      nodeVersion: '',
      selectAll: false,
      params: {},
    };
  },
  computed: {
    nodeUpdateColumns() {
      return [
        {
          text: this.$t('nodeUpdate.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdate.releaseDate'),
          value: 'releaseDate',
          sortable: false,
        },
      ];
    },
    compatibleNodesColumns() {
      return [
        {
          text: this.$t('nodeUpdate.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdate.serialNumber'),
          value: 'serialNumber',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdate.currentVersion'),
          value: 'currentFWVersion',
          sortable: false,
        },
      ];
    },
    getCompatibleNodes() {
      return this.$store.getters['node-update-list/getCompatibleDevices'];
    },
    getSelectedNodeDevices() {
      return this.$store.getters['node-update-list/getSelectedNodeDevices'];
    },
  },
  watch: {
    getCompatibleNodes() {
      this.isSelectedAll();
    },
    getSelectedNodeDevices() {
      this.isSelectedAll();
    },
  },
  destroyed() {
    this.$store.dispatch('node-update-list/remove_all_selected_devices');
  },
  methods: {
    async showCompatibleNodes(update) {
      try {
        this.nodeVersion = update.name;
        this.isCompatibleNodes = true;
        this.params.versionName = this.nodeVersion;
        await this.$store.dispatch('node-update-list/compatible_nodes', this.params);
      } catch (e) {
        Logger.error(e);
      }
    },
    async updateNodeToNewVersion() {
      try {
        await this.$store.dispatch('node-update-list/update_node_to_new_version', this.nodeVersion);
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'nodeUpdate.successfullyUpdated',
          color: 'primary',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Node Update Log' });
        }, 1200);
      } catch (e) {
        Logger.error(e);
      }
    },
    selectRemoveNodes() {
      // eslint-disable-next-line no-unused-expressions
      this.selectAll
        ? this.$store.dispatch('node-update-list/select_all_devices')
        : this.$store.dispatch('node-update-list/remove_all_selected_devices');
    },
    updateParams(parameters) {
      this.params = parameters;
    },
    isSelectedAll() {
      // eslint-disable-next-line max-len
      this.selectAll = this.getCompatibleNodes.every(
        (node) => !!this.getSelectedNodeDevices.find((slNode) => node.id === slNode.id),
      );
    },
  },
};
</script>
<style lang="scss">
#iiotNodeUpdateListCompatibleNodes {
  max-width: 100%;
  margin-top: 72px;
  max-height: 60%;
  .compatibleNodesCard {
    max-height: 60%;
  }
  .compatibleNodesTableEmpty {
    max-width: 100%;
    .default-table > .v-data-table__wrapper {
      max-height: 30vh;
    }
  }
  .compatibleNodesTable {
    max-width: 100%;
    .default-table > .v-data-table__wrapper {
      max-height: 30vh;
      padding-left: 40px;
    }
  }
}
.updateBtn {
  max-width: 190px;
  height: 40px;
}
</style>
